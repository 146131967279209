<template>
    <div class="prefixPhone">
        <van-index-bar v-for="(item,index) in phoneList" :key="index">
            <van-index-anchor :index="item.name" />
  <van-cell :title="subItem.Areaname" :value="subItem.value" v-for="(subItem,i) in item.text" :key="i" @click.prevent="toLogin(subItem.value)"/>

</van-index-bar>
    </div>
</template>

<script>
export default {
    name: 'prefixPhone',

    data() {
        return {
            phoneList:[
                {
                    name:'A',
                    text:[
                        {
                         Areaname:'阿尔巴尼亚',
                         value:'+355',
                        },
                        {
                        Areaname:'阿尔及利亚',
                        value:'+213',
                        },
                        {
                        Areaname:'阿富汗',
                        value:'+93',
                        },
                        {
                        Areaname:'阿根廷',
                        value:'+54',
                        }
                ] 
                },
                {
                    name:'B',
                    text:[
                        {
                         Areaname:'巴巴多斯',
                         value:'+1246',
                        },
                        {
                        Areaname:'巴哈马',
                        value:'+1242',
                        },
                        {
                        Areaname:'白俄罗斯',
                        value:'+375',
                        },
                ] 
                },
                {
                    name:'C',
                    text:[
                        {
                         Areaname:'朝鲜',
                         value:'+850',
                        },
                        {
                        Areaname:'赤道几亚',
                        value:'+240',
                        },
                ] 
                }
        ],
        };
    },

    mounted() {
        
    },

    methods: {
        toLogin(val)
        {
            this.$router.push('/login')
            this.$store.commit('setPrefix',val);
        }
    },
};
</script>

<style lang="less" scoped>
.prefixPhone{
    background-color: #eee;
    .van-cell__value{
        text-align: left;
    }
}
</style>